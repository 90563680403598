<template>
  <div>
      <div style="background:#3e06a5">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link to="/components/web/joinus/case01">
            <li class="tabStyleLi">
              <div class="tabStyle" style="background:#3246F4"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.zhaopin') }}</div>
              </div>
            </li>
          </router-link>
          <router-link to="/components/web/joinus/case02">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.peixunyufazhan') }}</div>
              </div>
            </li>
          </router-link>
          <router-link to="/components/web/joinus/case03">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.shenghuoyufuli') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">
            {{ $t('Nav.home') }}</span
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/joinus/index' }"
          ><span style="color:#303133">{{
            $t('Nav.abus')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">{{
            $t('Nav.zhaopin')
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style="width:1200px; margin:0 auto;" v-if="languageSet == 'zh'">
      <el-tabs :tab-position="tabPosition" style="margin:50px;">
        <el-tab-pane label="web前端工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">web前端工程师</div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、负责WEB前端页面的设计、开发与维护工作；
              </div>
              <div style="color:#9a9a9a">
                2、配合后台开发人员实现产品交互界面；
              </div>
              <div style="color:#9a9a9a">
                3、针对UI设计图能够进行切图，编写CSS规范，保证兼容性和执行高效性；
              </div>
              <div style="color:#9a9a9a">
                4、HTML5 CSS3 有移动端开发经验熟悉各主流移动端框架。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、3年以上Web前端开发经验，熟悉各类浏览器适配；
              </div>
              <div style="color:#9a9a9a">
                2、开发基础扎实，熟悉HTML5、CSS3、JavaScript、Ajax等Web前端开发技术；
              </div>
              <div style="color:#9a9a9a">
                3、熟练掌握vuejs/vue-router/vuex技术栈开发；
              </div>
              <div style="color:#9a9a9a">
                4、思路清晰，责任心强，具备良好的沟通能力、团队精神。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="需求分析师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">需求分析师</div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、独立进行需求调研、分析，能够很好的引导客户的需求方向，主动与客户、产品层、技术层进行沟通确认；
              </div>
              <div style="color:#9a9a9a">
                2、负责系统功能、界面和数据模型的设计；
              </div>
              <div style="color:#9a9a9a">
                3、参与需求、原型评审，确保需求准确的实现用户要求；
              </div>
              <div style="color:#9a9a9a">
                4、根据公司管理规范，独立完成需求分析文档、应用技术方案；
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、本科及以上学历；
              </div>
              <div style="color:#9a9a9a">
                2、熟悉需求分析的理论与方法，熟练使用Word、Visio、PPT、Axure等工具制作需求文档、原型图、流程图等；
              </div>
              <div style="color:#9a9a9a">
                3、沟通表达能力良好，具有积极的态度及团队合作精神；
              </div>
              <div style="color:#9a9a9a">
                4、具备良好的学习能力及较强的客户服务意识；
              </div>
              <div style="color:#9a9a9a">
                5、能充分发掘用户潜在需求、有较强的客户需求引导能力及需求分析把控能力；
              </div>
              <div style="color:#9a9a9a">
                6、要求2年及以上进销存软件项目经验；
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="产品经理">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">产品经理</div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1) 负责公司软件产品规划、产品设计、以及产品上线后续的迭代优化；
              </div>
              <div style="color:#9a9a9a">
                2)
                负责客户调研、收集和分析客户需求，编写需求文档，引导客户需求；帮助客户梳理管理流程，给予客户详细的解决方案；
              </div>
              <div style="color:#9a9a9a">
                3)
                负责编写产品文档、原型设计、用户手册、产品介绍和产品上线培训等工作；
              </div>
              <div style="color:#9a9a9a">
                4)
                负责编制项目实施计划，沟通协调内、外部资源，推进、跟踪实施过程，监控实施进度，按时保质交付实施成果，确保项目验收；
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1) 熟悉产品规划流程，熟悉软件功能规划和定义的方法；
              </div>
              <div style="color:#9a9a9a">
                2) 具备良好的逻辑分析能力，文档能力和口头交流能力；
              </div>
              <div style="color:#9a9a9a">
                3) 良好的编写能力，熟练使用Axure、Visio或其他原型设计软件；
              </div>
              <div style="color:#9a9a9a">
                4) 具备优秀的沟通能力，严谨的逻辑思维能力、分析解决问题能力。
              </div>
              <div style="color:#9a9a9a">
                5) 熟悉软件系统开发流程，二年以上软件开发经验；
              </div>
              <div style="color:#9a9a9a">
                6) 熟悉销售企业ERP或项目管理软件工作经验者优先；
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="Java开发工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">Java开发工程师</div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、协助高级工程师完成软件项目的系统分析和设计；
              </div>
              <div style="color:#9a9a9a">
                2、在高级工程师指导下，完成软件系统的开发、集成测试以及开发文档的编写；
              </div>
              <div style="color:#9a9a9a">
                3、协助需求分析人员做好项目的需求分析工作。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、计算机或相关专业大学本科以上学历；
              </div>
              <div style="color:#9a9a9a">
                2、2年以上Java软件项目开发工作经验，至少独立完成2个项目的开发；
              </div>
              <div style="color:#9a9a9a">
                3、精通Java、JavaScript、SpringMVC/Struts2、Spring、MyBatis/Hibernate等开发技术；
              </div>
              <div style="color:#9a9a9a">
                4、熟悉掌握Oracle、MySQL等数据库开发技术；
              </div>
              <div style="color:#9a9a9a">
                5、有良好的编程习惯，注重代码效率，能够编写规范，优质与高效的代码；
              </div>
              <div style="color:#9a9a9a">
                6、具备较强的学习能力和沟通能力，具备较好的团队合作能力；有责任感，工作态度严谨，能够承担高强度、高压力的工作；
              </div>
              <div style="color:#9a9a9a">
                7、有掌握dhtmlx UI组件的优先；
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="软件运维工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">软件运维工程师</div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、软件产品交付客户、安装软件产品
              </div>
              <div style="color:#9a9a9a">
                2、培训指导客户使用；
              </div>
              <div style="color:#9a9a9a">
                3、负责数据采集、系统数据导入导出和日常监控、运行管理；
              </div>
              <div style="color:#9a9a9a">
                4、负责协助解决现场系统出现的故障，以及系统管理运维工作。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.具备至少一年以上软件项目运维工作经验；
              </div>
              <div style="color:#9a9a9a">
                2.熟练掌握计算机软、硬件以及网络技术的基础知识；
              </div>
              <div style="color:#9a9a9a">
                3.有良好的沟通能力和团队精神；工作积极、进取，有较强的学习能力责任心强、耐心，工作有条理，思路清晰；能独立解决问题，具有应急处理问题能力。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="软件售前工程师/售前顾问">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              软件售前工程师/售前顾问
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、辅助销售经理进行软件开发技术支持工作；
              </div>
              <div style="color:#9a9a9a">
                2、负责与客户前期的软件开发项目的沟通和交流，并能引导挖掘客户的需求,完成客户的需求分析、项目方案；
              </div>
              <div style="color:#9a9a9a">
                3、制定IT技术方案和标书编写、讲解及用户答疑等工作；
              </div>
              <div style="color:#9a9a9a">
                4、根据公司战略发展方向，收集梳理和优化公司IT产品并进行推广；
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、计算机、软件开发或者网络相关专业，本科及以上学历，3年以上工作经验；
              </div>
              <div style="color:#9a9a9a">
                2、熟悉IT网络、系统、数据库等IT技术框架，有IT售前支持或项目经理工作经验优先考虑；有至少一年开发经验者优先考虑；
              </div>
              <div style="color:#9a9a9a">
                3、熟练掌握办公软件，PPT及技术文档编写能力佳；
              </div>
              <div style="color:#9a9a9a">
                4、具备良好的沟通协调能力、应变能力和团队协作意识，抗压能力。
              </div>
              <div style="color:#9a9a9a">
                5、具有强烈的事业心和进取心，善于执行任务、解决问题，能够适应出差。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="技术支持/维护工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              技术支持/维护工程师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、软件产品交付客户、安装软件产品；
              </div>
              <div style="color:#9a9a9a">
                2、培训指导客户使用；
              </div>
              <div style="color:#9a9a9a">
                3、熟悉0racle、mysql数据库优先考虑，负责系统日常监控、运行管理和优化事务；
              </div>
              <div style="color:#9a9a9a">
                4、负责协助解决现场系统出现的故障，以及系统管理运维工作。
              </div>
              <div style="color:#9a9a9a">
                5、客户提交的需求问题，与研发沟通解决。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.具备至少两年以上软件项目运维工作经验；
              </div>
              <div style="color:#9a9a9a">
                2.熟悉数据库的SQL、基本操作、性能调优及管理；
              </div>
              <div style="color:#9a9a9a">
                3.具有一定的计算机安全知识以及网络基础，能解决一定的网络故障；具备较强的文档理解能力和文档编写能力；
              </div>
              <div style="color:#9a9a9a">
                4.具有数据中心机房运维管理经验,熟悉服务器、交换机、存储、安全设备的日常管理与维护
              </div>
              <div style="color:#9a9a9a">
                5.有良好的沟通能力和团队精神；工作积极、进取，有较强的学习能力责任心强、耐心，工作有条理，思路清晰；能独立解决问题，具有应急处理问题能力。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="UI设计师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              UI设计师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、负责移动端APP、网站相关UI界面设计；web系统简易图标的制作。
              </div>
              <div style="color:#9a9a9a">
                2、公司产品宣传资料、广告等平面设计。
              </div>
              <div style="color:#9a9a9a">
                3、协助公司内部网站的页面美工设计和宣传。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、有丰富的设计理论知识和对流行趋势敏锐的洞察力，对UI设计趋势有灵敏触觉和领悟能力，推动产品视觉和UI的优化。
              </div>
              <div style="color:#9a9a9a">
                2、大专以及以上学历，计算机相关专业，精通网站/H5/APP的平面设计；熟悉CSS3等新的Web界面技术，能很好的把握色彩、排版、页面架构和布局。
              </div>
              <div style="color:#9a9a9a">
                3、从事设计行业工作2年以上，具有移动端网站、APP视觉设计（包括界面、图标风格与统一规范等）经验。
              </div>
              <div style="color:#9a9a9a">
                4、对视觉用户研究有一定经验和见解，对移动互联网产品可用性有深入的认识
              </div>
              <div style="color:#9a9a9a">
                5、工作细心、责任心强，具有较强的理解沟通能力、领悟能力、工作协调能力及创造力。
              </div>
              <div style="color:#9a9a9a">
                6、面试请携带作品。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="HTML5开发工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              HTML5开发工程师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、根据产品设计文档和UI原型，利用HTML5相关技术开发PC端及移动端的web前端页面；
              </div>
              <div style="color:#9a9a9a">
                2、基于HTML5.0标准进行页面制作，开发通用功能sdk或者组件；
              </div>
              <div style="color:#9a9a9a">
                3、持续的优化前端体验和页面响应速度，并保证兼容性和执行效率；
              </div>
              <div style="color:#9a9a9a">
                4、配合后台工程师一起研讨技术实现方案，进行应用及系统整合；
              </div>
              <div style="color:#9a9a9a">
                5、帮助团队完成组件化开发进程以及对旧有业务进行组件化重构；
              </div>
              <div style="color:#9a9a9a">
                6、收集用户反馈，分析用户行为及需求，对产品进行持续的优化和改进；
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.大专以及以上学历，计算机相关专业，有两年及以上Html5开发经验；
              </div>
              <div style="color:#9a9a9a">
                2.精通Web前端技术，包括XHTML/XML/CSS/JavaScript/Ajax等，熟悉W3C标准，对页面的性能和浏览器兼容有丰富的实践经验，熟悉Html5、CSS3.0等；
              </div>
              <div style="color:#9a9a9a">
                3.熟悉手机端和pc端和web端的实现差异；
              </div>
              <div style="color:#9a9a9a">
                4.熟悉各种前端框架,熟练使用Bootstrap、WeUI、JQuery、Vue等框架；
              </div>
              <div style="color:#9a9a9a">
                5、勤奋敬业实干、责任心强；积极进取，学习适应能力强；善于沟通、具备良好的团队合作精神和抗压能力、思想活跃。分析问题和实际动手能力强；
              </div>
              <div style="color:#9a9a9a">
                6、有JAVA开始经验者优先；
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="数据分析师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              数据分析师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、负责各业务线运营数据的监控、处理和分析，及时发现数据异常、业务异常;
              </div>
              <div style="color:#9a9a9a">
                2、负责各业线行业分析、产品分析、用户研究、经营分析等方面报告的输出，并提供专业建议;
              </div>
              <div style="color:#9a9a9a">
                3、负责数据挖掘方面工作，构建挖掘模型，探索数据本质、发现运营规律、攻坚业务难题;
              </div>
              <div style="color:#9a9a9a">
                4、参与大数据平台规划和数据产品设计。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、本科及以上学历，数学、统计学、计算机等相关专业，2年以上相关工作经历；精通SQL语言；能独立撰写专题分析报告
              </div>
              <div style="color:#9a9a9a">
                2、熟悉常用数据挖掘算法原理及应用场景，熟悉SPSS/R/PYTHON/SAS任一大型数据挖掘软件者优先;
              </div>
              <div style="color:#9a9a9a">
                3、有较好的数据敏感性、理解力、洞察力，以及互联网思维;
              </div>
              <div style="color:#9a9a9a">
                4、有基于互联网用户数据对用户画像、用户行为研究、精准营销等经验的优先;
              </div>
              <div style="color:#9a9a9a">
                5、综合素质好，有较强的沟通协调能力、学习能力及推动能力、善于执行和监控，有较强的组织和责任意识，性格开朗，善于合作，团队意识强；沟通能力、逻辑思维能力比较强。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【职位描述】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.负责数据分析团队的日常管理工作。
              </div>
              <div style="color:#9a9a9a">
                2.负责建立业务分析体系，开展数据分析工作，周期性地对数据处理与分析，进行商业价值挖掘，定期形成整体数据分析结果。
              </div>
              <div style="color:#9a9a9a">
                3.基于数据分析结果，为业务运营团队提供商业策略分析和业务运营优化建议。
              </div>
              <div style="color:#9a9a9a">
                4.持续优化和完善数据分析体系、运营指标规划，包括KPI指标体系、报表体系、专题分析体系。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.计算机、统计、数学、信息技术大专及以上学历优先；
              </div>
              <div style="color:#9a9a9a">
                2.2年以上的数据分析工作经验，有房地产、互联网、电商、零售背景优先考虑。
              </div>
              <div style="color:#9a9a9a">
                3.熟悉SQL语句，可熟练使用至少一种数据分析工具(SPSS、SAS、Excel等)，较强的分析能力，逻辑推理能力，严谨细致，学习能力强。
              </div>
              <div style="color:#9a9a9a">
                4.工作态度严谨认真，责任心强，具备良好的团队协作能力和良好的沟通能力。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="软件测试工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              软件测试工程师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.根据项目需求制定测试计划，完成项目中测试计划及测试文档撰写；
              </div>
              <div style="color:#9a9a9a">
                2.根据需求规格说明书，进行测试设计，编写测试用例；
              </div>
              <div style="color:#9a9a9a">
                3.执行测试工作，记录Bug并跟踪其解决，测试结束编写测试报告；
              </div>
              <div style="color:#9a9a9a">
                4.独立完成集成测试、系统测试、上线测试，对产品质量负责；
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、本科及以上；计算机或相关专业优先；
              </div>
              <div style="color:#9a9a9a">
                2、熟练软件测试流程，2年以上软件测试相关经验优先；
              </div>
              <div style="color:#9a9a9a">
                3、具备软件工程或者项目管理的基本知识者优先，熟练掌握各种测试理论和测试技术；
              </div>
              <div style="color:#9a9a9a">
                4、具有较强的独立工作能力和解决问题的能力；
              </div>
              <div style="color:#9a9a9a">
                5、熟练测试过程和用例设计方法，能主动进行技术钻研。良好的文档写作能力。
              </div>
              <div style="color:#9a9a9a">
                6、具备很强的逻辑思维能力和较高的分析、处理问题的能力；
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="系统运维工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              系统运维工程师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.
                熟练掌握计算机软、硬件以及网络技术的基础知识，能够准确判客户需求；
              </div>
              <div style="color:#9a9a9a">
                2. 熟悉常用的操作系统的安装、操作；
              </div>
              <div style="color:#9a9a9a">
                3. 具备系统硬件架构(服务器以及附属设备)的实施部署工作能力；
              </div>
              <div style="color:#9a9a9a">
                4. 具备服务器数据库备份、运营及维护能力。
              </div>
              <div style="color:#9a9a9a">
                5. 有IT或者相关行业的从业经验，有呼叫中心从业经验者优先；
              </div>
              <div style="color:#9a9a9a">
                6.
                具备良好的服务意识与职业素养，沟通能力和良好的语言表达能力，具备团队协作精神；
              </div>
              <div style="color:#9a9a9a">
                7.
                工作地点在广州市天河区黄埔大道中199号中石油大厦，工作条件忧越，交通便利；
              </div>
              <div style="color:#9a9a9a">
                8.
                工作性质以技术服务为主，主要服务方面有加油站运用软件（加油站管理系统）系统（带薪培训）配置、处理简单硬件故障；
              </div>
              <div style="color:#9a9a9a">
                9.
                提供全职上岗专业软件培训一个月，培训期间工资待遇与正式工作一致。
              </div>
              <div style="color:#9a9a9a">
                10. 转正后发住宿补贴；
              </div>
              <div style="color:#9a9a9a">
                11. 男女不限。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="服务器运维工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              服务器运维工程师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、负责公司主机房服务器的日常巡检与维护工作，定期检查服务器系统日志，保证公司服务器正常运行；
              </div>
              <div style="color:#9a9a9a">
                2、定期检查服务器软硬件，及时处理硬件、软件运行中出现的各种错误，对出现的大小故障作详细的记录，包括故障时间，故障现象、处理方法和结果，保障服务；
              </div>
              <div style="color:#9a9a9a">
                3、负责日常定期数据备份和恢复；
              </div>
              <div style="color:#9a9a9a">
                4、负责定时检查主机房温湿度、设备运行状态，记录各项异常情况，排除潜在的安全隐患；
              </div>
              <div style="color:#9a9a9a">
                5、响应公司各职能部门提出的各类系统IT需求，及时提供相应解决方案。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1.专科以上学历，30岁以下
              </div>
              <div style="color:#9a9a9a">
                2. 3 年以上的一线运维和现场支持经验；
              </div>
              <div style="color:#9a9a9a">
                3.
                良好的语言沟通、邮件沟通能力和解决问题的能力，具备良好的文档能力；
              </div>
              <div style="color:#9a9a9a">
                4. 熟悉 MS Windows Server ,MS SQL Server 的管理及维护;
              </div>
              <div style="color:#9a9a9a">
                5. 丰富的服务器、存储、网络、中间件、数据库运维经验;
              </div>
              <div style="color:#9a9a9a">
                6、熟悉 AD 域 、Exchange server 服务软件的操作、维护、管理经验；
              </div>
              <div style="color:#9a9a9a">
                7、熟悉主流window、Linux等服务器系统安装方法及相关安全设置，有独立判断、处理、快速恢复服务器基本异常的能力；
              </div>
              <div style="color:#9a9a9a">
                8、熟练掌握各种AD域控方面的知识；
              </div>
              <div style="color:#9a9a9a">
                9、对数据库有认知，熟悉基本备份、导出功能；
              </div>
              <div style="color:#9a9a9a">
                10、做事认真，仔细，负责，及时响应处理各类应急事件；
              </div>
              <div style="color:#9a9a9a">
                11、有团队精神，能吃苦耐劳，善于沟通联络，有良好的职业操守，保密意识强,工作积极认真，能服从工作安排岗位要求；
              </div>
              <div style="color:#9a9a9a">
                12、了解HP、DELL、浪潮、联想、IBM服务器。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【薪酬福利】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、国企工作。
              </div>
              <div style="color:#9a9a9a">
                2、到岗当月起购买五险一金；
              </div>
              <div style="color:#9a9a9a">
                3、按国家规定享受各类带薪假期；
              </div>
              <div style="color:#9a9a9a">
                4、公司不定期举办各类员工活动（旅游、聚餐、团建活动等）；
              </div>
              <div style="color:#9a9a9a">
                5、每年提供一次健康体检。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="网络运维工程师">
          <el-card class="box-card" style="text-align:left;">
            <div style="color:#455bfe; font-size:20px;">
              网络运维工程师
            </div>
            <div style="margin-top:30px">
              <span style="color:#333333">【岗位职责】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、负责运维管理工作，包含桌面运维管理；
              </div>
              <div style="color:#9a9a9a">
                2、负责服务器、网络设备、网络监控，故障处理和排查等运维工作；
              </div>
              <div style="color:#9a9a9a">
                3、管理设备仓库、机房、所有运维资料档案；
              </div>
              <div style="color:#9a9a9a">
                4、完成上级交办的其他工作；
              </div>
              <div style="color:#9a9a9a">
                5、广州银行大厦驻客户现场服务。
              </div>
            </div>
            <div style="margin-top:30px;">
              <span style="color:#333333">【岗位要求】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1. 本科及以上学历，35岁以下
              </div>
              <div style="color:#9a9a9a">
                2. 3 年以上的一线运维和现场支持经验；3 年网络管理经验,具有
                LAN,WAN 以及互联网关管理经验;
              </div>
              <div style="color:#9a9a9a">
                3.良好的语言沟通、邮件沟通能力和解决问题的能力，具备良好的文档撰写能力；熟悉手机端和pc端和web端的实现差异；
              </div>
              <div style="color:#9a9a9a">
                4. 熟悉Linux系统、WinServer ,SQL Server、ORACLE、mycql
                的管理及维护;
              </div>
              <div style="color:#9a9a9a">
                5、丰富的服务器、存储、网络、中间件、数据库运维经验;
              </div>
              <div style="color:#9a9a9a">
                6. 熟悉 Cisco 网络设备的配置及管理, 具备 CCNA
                或同等级华为华三认证;
              </div>
              <div style="color:#9a9a9a">
                7. 熟悉 VPN 防火墙的日常管理与配置；
              </div>
              <div style="color:#9a9a9a">
                8. 熟悉 AD 域 、Exchange server 服务软件的操作、维护、管理经验；
              </div>
              <div style="color:#9a9a9a">
                9、具备扎实的数据通讯基础知识，熟练掌握TCP/IP协议、VLAN技术原理、OSPF路由协议【对运营商各传输线路，语音线路(E1和VOIP)以及互联VPN熟悉者优先】；
              </div>
              <div style="color:#9a9a9a">
                10、熟悉cisco，华为，h3c等主流交换机、路由器、防火墙等网络设备的配置；
              </div>
              <div style="color:#9a9a9a">
                11、熟悉VMWARE虚机管理、熟悉Windows和Linux操作系统管理和维护，熟悉刀片服务器，存储等硬件配置和管理。
              </div>
            </div>

            <div style="margin-top:30px;">
              <span style="color:#333333">【薪酬福利】：</span>
            </div>
            <div>
              <div style="color:#9a9a9a">
                1、到岗当月起购买五险一金；
              </div>
              <div style="color:#9a9a9a">
                2、按国家规定享受各类带薪假期；
              </div>
              <div style="color:#9a9a9a">
                3、公司不定期举办各类员工活动（旅游、聚餐、团建活动等）；
              </div>
              <div style="color:#9a9a9a">
                4、每年提供一次健康体检。
              </div>
              <div style="color:#9a9a9a">
                5、大型合资企业工作环境。
              </div>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div style="width:1200px; margin:0 auto;" v-if="languageSet == 'en'">
      <div style="text-align:left;margin:20px;">
        <div>Web front end development engineer</div>
        <div>[Job Responsibilities]:</div>
        <div>
          1. Responsible for the design, development and maintenance of Web
          front-end pages;
        </div>
        <div>
          2. Cooperate with background developers to realize product
          interaction;
        </div>
        <div>
          3. Be able to cut the UI design diagram and write CSS specifications
          to ensure compatibility and execution efficiency;
        </div>

        <div>
          4. HTML5 CSS3 has mobile terminal development experience and is
          familiar with mainstream mobile terminal frameworks.
        </div>

        <div>[job requirements]:</div>
        <div>
          1. At least 3 years of Web front-end development experience, familiar
          with all kinds of browser adaptation;
        </div>
        <div>
          2. Solid development foundation, familiar with web front-end
          development technologies such as HTML5, CSS3, JavaScript and Ajax;
        </div>
        <div>
          3. Master the development of vuejs / Vue router / vuex technology
          stack;
        </div>
        <div>
          4. Clear thinking, strong sense of responsibility, good communication
          skills and team spirit.
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Demand analyst</div>
        <div>Job responsibilities:</div>
        <div>
          1. Independently conduct demand research and analysis, be able to well
          guide the demand direction of customers, and actively communicate and
          confirm with customers, product level and technical level;
        </div>
        <div>
          2. Responsible for the design of system function, interface and data
          model;
        </div>
        <div>
          3. Participate in requirements and prototype review to ensure that
          requirements accurately meet user requirements;
        </div>
        <div>
          4. Independently complete requirements analysis documents and
          application technology solutions according to the company's management
          specifications;
        </div>
        <div>Job requirements:</div>
        <div>1. Bachelor degree or above;</div>
        <div>
          2. Be familiar with the theory and method of requirements analysis,
          and skillfully use word, Visio, PPT, Axure and other tools to make
          requirements documents, prototype diagrams, flow charts, etc;
        </div>
        <div>
          3. Good communication skills, positive attitude and team spirit;
        </div>
        <div>
          4. Good learning ability and strong customer service awareness;
        </div>
        <div>
          5. Be able to fully explore the potential needs of users, have strong
          customer demand guidance ability and demand analysis and control
          ability;
        </div>
        <div>
          6. At least 2 years experience in purchase, sales and inventory
          software project is required;
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>product manager</div>
        <div>operating duty:</div>
        <div>
          1) Responsible for the company's software product planning, product
          design, and subsequent iterative optimization of product launch;
        </div>
        <div>
          2) Responsible for customer research, collection and analysis of
          customer needs, preparation of requirements documents and guidance of
          customer needs; Help customers sort out the management process and
          give customers detailed solutions;
        </div>
        <div>
          3) Responsible for preparing product documents, prototype design, user
          manual, product introduction and product on-line training;
        </div>
        <div>
          4) Prepare the project implementation plan, communicate and coordinate
          internal and external resources, promote and track the implementation
          process, monitor the implementation progress, deliver the
          implementation results on time and with quality, and ensure the
          project acceptance;
        </div>
        <div>Job requirements:</div>
        <div>
          1) Familiar with product planning process, familiar with software
          function planning and definition methods;
        </div>
        <div>
          2) Good logical analysis, documentation and oral communication skills;
        </div>
        <div>
          3) Good writing skills, proficient in using Axure, Visio or other
          prototype design software;
        </div>
        <div>
          4) Excellent communication skills, rigorous logical thinking ability,
          analysis and problem solving ability.
        </div>
        <div>
          5) Familiar with software system development process, more than two
          years of software development experience;
        </div>
        <div>
          6) Familiar with ERP or project management software in sales
          enterprise is preferred;
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Java Development Engineer</div>
        <div>Job responsibilities:</div>
        <div>
          1. Assist senior engineer to complete system analysis and design of
          software project;
        </div>
        <div>
          2. Under the guidance of senior engineer, complete software system
          development, integration test and development document writing;
        </div>
        <div>
          3. Assist the demand analysts in the demand analysis of the project.
        </div>
        <div>Job requirements:</div>
        <div>1. Bachelor degree or above in computer or related major;</div>
        <div>
          2. At least 2 years working experience in Java software project
          development, and complete the development of at least 2 projects
          independently;
        </div>
        <div>
          2. Proficient in Java, JavaScript, spring MVC / struts 2, spring,
          mybatis / Hibernate and other development technologies;
        </div>
        <div>
          3. Familiar with Oracle, MySQL and other database development
          technologies;
        </div>
        <div>
          4. Have good programming habits, pay attention to code efficiency, and
          be able to write standard, high-quality and efficient code;
        </div>
        <div>
          5. Strong learning and communication skills, good team cooperation
          ability; Sense of responsibility, rigorous working attitude, able to
          undertake high-intensity and high-pressure work;
        </div>
        <div>6. Master dhtmlx UI components is preferred;</div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Software operation and maintenance engineer</div>
        <div>Job responsibilities:</div>
        <div>
          1. Deliver software products to customers and install software
          products;
        </div>
        <div>2. Train and guide customers to use;</div>
        <div>
          3. Responsible for data acquisition, system data import and export,
          daily monitoring and operation management;
        </div>
        <div>
          4. Be responsible for assisting in solving the faults of the on-site
          system, as well as system management, operation and maintenance.
        </div>
        <div>Job requirements:</div>
        <div>
          1. At least one year working experience in software project operation
          and maintenance;
        </div>
        <div>
          2. Master the basic knowledge of computer software, hardware and
          network technology;
        </div>
        <div>
          3. Good communication skills and team spirit; Work actively,
          enterprising, have strong learning ability, strong sense of
          responsibility, patience, organized work and clear thinking; Be able
          to solve problems independently and have the ability to deal with
          problems in an emergency.
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Software pre sales engineer / Consultant</div>
        <div>
          1. Assist sales manager in software development and technical support;
        </div>
        <div>
          2. Be responsible for the communication and exchange with customers in
          the early stage of software development projects, guide and excavate
          customers' needs, and complete customers' demand analysis and project
          scheme;
        </div>
        <div>
          2. Formulate it technical scheme and preparation, explanation and user
          Q & A of bidding documents;
        </div>
        <div>
          3. Collect, sort out and optimize the company's IT products and
          promote them according to the company's strategic development
          direction;
        </div>
        <div>Job requirements:</div>
        <div>
          1. Bachelor degree or above, major in computer, software development
          or network, more than 3 years working experience;
        </div>
        <div>
          2. Familiar with it technology framework such as it network, system
          and database, working experience in it pre-sales support or project
          manager is preferred; At least one year development experience is
          preferred;
        </div>
        <div>
          3. Proficient in office software, PPT and technical document writing
          ability;
        </div>
        <div>
          4. Good communication and coordination skills, adaptability, teamwork
          awareness and pressure resistance
        </div>
        <div>
          5. Have strong dedication and enterprising spirit, be good at
          performing tasks and solving problems, and be able to travel.
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Technical support / Maintenance Engineer</div>
        <div>Job responsibilities:</div>
        <div>
          1. Deliver software products to customers and install software
          products;
        </div>
        <div>2. Train and guide customers to use;</div>
        <div>
          3. Familiar with 0race and MySQL database is preferred, responsible
          for daily system monitoring, operation management and optimization;
        </div>
        <div>
          4. Be responsible for assisting in solving the faults of the on-site
          system, as well as system management, operation and maintenance.
        </div>
        <div>
          5. Communicate with R & D to solve the demand problems submitted by
          customers.
        </div>
        <div>Job requirements:</div>
        <div>
          1. At least two years working experience in software project operation
          and maintenance;
        </div>
        <div>
          2. Be familiar with SQL, basic operation, performance tuning and
          management of database;
        </div>
        <div>
          3. Have certain computer security knowledge and network foundation,
          and be able to solve certain network faults; Strong document
          understanding ability and document writing ability;
        </div>
        <div>
          4. Have experience in operation and maintenance management of data
          center computer room, and be familiar with the daily management and
          maintenance of server, switch, storage and security equipment
        </div>
        <div>
          5. Good communication skills and team spirit; Work actively,
          enterprising, have strong learning ability, strong sense of
          responsibility, patience, organized work and clear thinking; Be able
          to solve problems independently and have the ability to deal with
          problems in an emergency.
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>UI Designer</div>
        <div>Job responsibilities:</div>
        <div>
          1. Responsible for UI interface design of mobile app and website; Web
          system simple icon making.
        </div>
        <div>
          2. Graphic design of publicity materials and advertisements of the
          company's products.
        </div>
        <div>
          3. Assist in page art design and publicity of the company's internal
          website.
        </div>
        <div>Job requirements:</div>
        <div>
          1. Have rich design theoretical knowledge and keen insight into
          popular trends, have sensitive touch and understanding ability of UI
          design trends, and promote the optimization of product vision and UI.
        </div>
        <div>
          2. College degree or above, major in computer related, proficient in
          graphic design of website / H5 / APP; Familiar with CSS3 and other new
          web interface technologies, and can well grasp color, layout, page
          architecture and layout.
        </div>
        <div>
          3. At least 2 years working experience in the design industry, with
          experience in mobile website and app visual design (including
          interface, icon style and unified specification).
        </div>
        <div>
          4. Have some experience and opinions on visual user research, and have
          an in-depth understanding of the availability of mobile Internet
          products.
        </div>
        <div>
          5. Careful work, strong sense of responsibility, strong understanding
          and communication skills, understanding ability, work coordination
          ability and creativity.
        </div>
        <div>6. Please bring your work for the interview.</div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>HTML5 Development Engineer</div>
        <div>Job responsibilities</div>
        <div>
          1. According to the product design documents and UI prototypes, the
          web front-end pages of PC and mobile terminals are developed by using
          HTML5 related technologies;
        </div>
        <div>
          2. Make pages and develop general function SDK or components based on
          html5.0 standard;
        </div>
        <div>
          3. Continuously optimize the front-end experience and page response
          speed, and ensure compatibility and execution efficiency;
        </div>
        <div>
          4. Cooperate with the background engineer to discuss the technical
          implementation scheme, application and system integration;
        </div>
        <div>
          5. Help the team to complete the component-based development process
          and component-based reconstruction of the old business;
        </div>
        <div>
          6. Collect user feedback, analyze user behavior and needs, and
          continuously optimize and improve products;
        </div>
        <div>Job description:</div>
        <div>
          1. College degree or above, major in computer related, with more than
          two years of HTML5 development experience;
        </div>
        <div>
          2. Proficient in Web front-end technology, including XHTML / XML / CSS
          / JavaScript / Ajax, familiar with W3C standards, rich practical
          experience in page performance and browser compatibility, familiar
          with HTML5, css3.0, etc;
        </div>
        <div>
          3. Be familiar with the implementation differences between mobile
          terminal, PC terminal and Web terminal;
        </div>
        <div>
          4. Be familiar with various front-end frameworks and skillfully use
          bootstrap, weui, jQuery, Vue and other frameworks;
        </div>
        <div>
          6. Diligence, dedication, hard work and strong sense of
          responsibility; Positive and enterprising, strong learning
          adaptability; Good at communication, good team spirit and pressure
          resistance, active thinking. Strong problem analysis and practical
          ability;
        </div>
        <div>7. Java starting experience is preferred;</div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Data Analyst</div>
        <div>Job responsibilities:</div>
        <div>
          1. Be responsible for monitoring, processing and analyzing the
          operation data of each business line, and timely find data and
          business abnormalities;
        </div>
        <div>
          2. Be responsible for the output of reports on industry analysis,
          product analysis, user research and business analysis of various
          business lines, and provide professional suggestions;
        </div>
        <div>
          3. Be responsible for data mining, build mining model, explore data
          essence, discover operation rules and tackle business problems;
        </div>
        <div>
          4. Participate in big data platform planning and data product design.
        </div>
        <div>Job requirements:</div>
        <div>
          1. Bachelor degree or above, major in mathematics, statistics,
          computer and other related majors, more than 2 years of relevant
          working experience; Proficient in SQL language; Be able to write
          special analysis reports independently;
        </div>
        <div>
          2. Familiar with the principles and application scenarios of common
          data mining algorithms, familiar with any large-scale data mining
          software SPSS / R / Python / SAS is preferred;
        </div>
        <div>
          3. Good data sensitivity, understanding, insight and Internet
          thinking;
        </div>
        <div>
          4. Experience in user portrait, user behavior research and precision
          marketing based on Internet user data is preferred;
        </div>
        <div>
          5. Good comprehensive quality, strong communication and coordination
          ability, learning ability and promotion ability, good at
          implementation and monitoring, strong sense of organization and
          responsibility, cheerful personality, good at cooperation and strong
          sense of team; Strong communication skills and logical thinking
          skills.
        </div>
        <div>Job description</div>
        <div>
          1. Bachelor degree or above, major in mathematics, statistics,
          computer and other related majors, more than 2 years of relevant
          working experience; Proficient in SQL language; Be able to write
          special analysis reports independently;
        </div>
        <div>
          2. Familiar with the principles and application scenarios of common
          data mining algorithms, familiar with any large-scale data mining
          software SPSS / R / Python / SAS is preferred;
        </div>
        <div>
          3. Good data sensitivity, understanding, insight and Internet
          thinking;
        </div>
        <div>
          4. Experience in user portrait, user behavior research and precision
          marketing based on Internet user data is preferred;
        </div>
        <div>
          5. Good comprehensive quality, strong communication and coordination
          ability, learning ability and promotion ability, good at
          implementation and monitoring, strong sense of organization and
          responsibility, cheerful personality, good at cooperation and strong
          sense of team; Strong communication skills and logical thinking
          skills.
        </div>
        <div>Job description</div>
        <div>
          1. Be responsible for the daily management of the data analysis team.
        </div>
        <div>
          2. Be responsible for establishing business analysis system, carrying
          out data analysis, periodically processing and analyzing data, mining
          business value, and regularly forming overall data analysis results.
        </div>
        <div>
          3. Provide business strategy analysis and business operation
          optimization suggestions for the business operation team based on the
          data analysis results.
        </div>
        <div>
          4. Continuously optimize and improve the data analysis system and
          operation index planning, including KPI index system, report system
          and special analysis system.
        </div>
        <div>Job requirements:</div>
        <div>
          1. College degree or above in computer, statistics, mathematics and
          information technology is preferred;
        </div>
        <div>
          2. More than 2 years of data analysis experience, with real estate,
          Internet, e-commerce and retail background is preferred.;
        </div>
        <div>
          3. Familiar with SQL statements, proficient in using at least one data
          analysis tool (SPSS, SAS, Excel, etc.), strong analysis ability,
          logical reasoning ability, rigorous and meticulous, and strong
          learning ability.
        </div>
        <div>
          4. Rigorous and serious working attitude, strong sense of
          responsibility, good teamwork ability and good communication ability.
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Software Test Engineer</div>
        <div>Job responsibilities:</div>
        <div>
          1. Formulate the test plan according to the project requirements and
          complete the writing of the test plan and test documents in the
          project;
        </div>
        <div>
          2. Carry out test design and write test cases according to the
          requirements specification;
        </div>
        <div>
          3. Execute the test work, record bugs and track their solutions, and
          prepare the test report after the test;
        </div>
        <div>
          4. Independently complete integration test, system test and on-line
          test, and be responsible for product quality;
        </div>
        <div>Job requirements:</div>
        <div>
          1. Bachelor degree or above; Computer or related major is preferred;
        </div>
        <div>
          2. Familiar with software testing process, more than 2 years of
          software testing experience is preferred;
        </div>
        <div>
          3. Basic knowledge of software engineering or project management is
          preferred, and proficient in various testing theories and
          technologies;
        </div>
        <div>4. Strong ability to work independently and solve problems;</div>
        <div>
          5. Proficient in test process and use case design method, and can
          actively study technology. Good document writing skills.
        </div>
        <div>
          6. Have strong logical thinking ability and high ability to analyze
          and deal with problems;
        </div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Service Engineer</div>
        <div>Job responsibilities:</div>
        <div>
          1. Master the basic knowledge of computer software, hardware and
          network technology, and be able to accurately judge customer needs;
        </div>
        <div>
          2. Be familiar with the installation and operation of common operating
          systems;
        </div>
        <div>
          3. Have the ability to implement and deploy the system hardware
          architecture (server and auxiliary equipment);
        </div>
        <div>
          4. Have the ability of server database backup, operation and
          maintenance.
        </div>
        <div>
          5. Experience in it or related industries, call center experience is
          preferred;
        </div>
        <div>
          6. Have good service awareness and professional quality, communication
          skills, good language expression skills, and team spirit;
        </div>
        <div>
          7. The working place is PetroChina building, No. 199, Huangpu Avenue,
          Tianhe District, Guangzhou, with worrying working conditions and
          convenient transportation;
        </div>
        <div>
          8. The nature of work is mainly technical services. The main services
          include the configuration of gas station application software (gas
          station management system) system (paid training) and handling simple
          hardware faults;
        </div>
        <div>
          9. Provide full-time professional software training for one month, and
          the salary during the training period is consistent with the formal
          work.
        </div>
        <div>
          10. Accommodation allowance will be given after becoming a regular;
        </div>
        <div>11. Men and women are not limited.</div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>Server operation and maintenance engineer</div>
        <div>Job responsibilities:</div>
        <div>
          1. Be responsible for the daily patrol inspection and maintenance of
          the company's mainframe server, regularly check the server system log
          to ensure the normal operation of the company's server;
        </div>
        <div>
          2. Regularly check the size and hardware faults of the server, and
          timely record the handling results of the faults in the software and
          hardware;
        </div>
        <div>
          3. Be responsible for daily and regular data backup and recovery;
        </div>
        <div>
          4. Be responsible for regularly checking the temperature, humidity and
          equipment operation status of the main engine room, recording various
          abnormalities and eliminating potential safety hazards;
        </div>
        <div>
          5. Respond to various system it requirements put forward by various
          functional departments of the company and provide corresponding
          solutions in time.
        </div>
        <div>Job requirements:</div>
        <div>College degree or above, under 30</div>
        <div>
          1. More than 3 years of front-line operation and maintenance and
          on-site support experience;
        </div>
        <div>
          3. Good language communication, e-mail communication skills and
          problem solving skills, with good documentation skills;
        </div>
        <div>
          4. Be familiar with the management and maintenance of MS Windows
          Server and MS SQL Server;
        </div>
        <div>
          5. Rich experience in server, storage, network, middleware and
          database operation and maintenance;
        </div>
        <div>
          6. Familiar with the operation, maintenance and management experience
          of ad domain and exchange server service software;
        </div>
        <div>
          7. Be familiar with the installation methods and relevant security
          settings of mainstream windows, Linux and other server systems, and
          have the ability to independently judge, deal with and quickly recover
          the basic abnormalities of the server;
        </div>
        <div>8. Master various knowledge of ad domain control;</div>
        <div>
          9. Knowledge of database, familiar with basic backup and export
          functions;
        </div>
        <div>
          10. Be serious, careful and responsible, respond and deal with all
          kinds of emergency events in time;
        </div>
        <div>
          11. Have team spirit, be hardworking, good at communication, have good
          professional ethics, strong sense of confidentiality, work actively
          and seriously, and be able to obey the job arrangement and post
          requirements;
        </div>
        <div>12. Learn about HP, Dell, Inspur, Lenovo and IBM servers.</div>
        <div>Compensation and benefits:</div>
        <div>1. Work in state-owned enterprises.</div>
        <div>
          2. Purchase five insurances and one fund from the month of arrival;
        </div>
        <div>
          3. Enjoy various paid holidays according to national regulations;
        </div>
        <div>
          4. The company holds various employee activities (tourism, dinner,
          group building activities, etc.) from time to time;
        </div>
        <div>5. A physical examination is provided once a year.</div>
      </div>
      <div style="text-align:left;margin:20px;">
        <div>IDC Network & Operation</div>
        <div>Job responsibilities:</div>
        <div>
          1. Responsible for operation and maintenance management, including
          desktop operation and maintenance management;
        </div>
        <div>
          2. Be responsible for the operation and maintenance of servers,
          network equipment, network monitoring, fault handling and
          troubleshooting;
        </div>
        <div>
          3. Manage equipment warehouse, machine room and all operation and
          maintenance data files;
        </div>
        <div>4. Complete other tasks assigned by the superior;</div>
        <div>5. On site customer service of Bank of Guangzhou building.</div>
        <div>Job requirements:</div>
        <div>Bachelor degree or above, under 35</div>
        <div>
          1. More than 3 years of front-line operation and maintenance and
          on-site support experience; 3 years of network management experience,
          LAN, Wan and Internet gateway management experience;
        </div>
        <div>
          2. Good language communication, e-mail communication skills and
          problem-solving skills, and good document writing skills;
        </div>
        <div>
          4. Be familiar with the management and maintenance of Linux system,
          winserver, SQL server, Oracle and mycql;
        </div>
        <div>
          6. Rich experience in server, storage, network, middleware and
          database operation and maintenance;
        </div>
        <div>
          7. Be familiar with the configuration and management of Cisco network
          equipment, and have CCNA or Huawei H3C certification of the same
          level;
        </div>
        <div>
          8. Be familiar with the daily management and configuration of VPN
          firewall;
        </div>
        <div>
          9. Be familiar with the operation, maintenance and management
          experience of ad domain and exchange server service software;
        </div>
        <div>
          10. Have solid basic knowledge of data communication, master TCP / IP
          protocol, VLAN technical principle and OSPF routing protocol
          [familiarity with transmission lines, voice lines (E1 and VoIP) and
          Internet VPN of operators is preferred];
        </div>
        <div>
          11. Familiar with Cisco, Huawei, H3C and other mainstream switches,
          routers, firewalls and other network equipment configuration;
        </div>
        <div>
          12. Familiar with VMware virtual machine management, windows and Linux
          operating system management and maintenance, blade server, storage and
          other hardware configuration and management.
        </div>
        <div>Compensation and benefits:</div>
        <div>
          1. Purchase five insurances and one fund from the month of arrival;
        </div>
        <div>
          2. Enjoy various paid holidays according to national regulations;
        </div>
        <div>
          3. The company holds various employee activities (tourism, dinner,
          group building activities, etc.) from time to time;
        </div>
        <div>4. A physical examination is provided once a year.</div>
        <div>5. Working environment of large joint venture</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'case01',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/55.jpg'),
      corporateVision1: require('../../../assets/aboutus/case01.png'),
      tabPosition: 'left',
      languageSet: 'zh',
    }
  },
  mounted() {
    let that = this
    this.$bus.$on('changeLanguage', function(d) {
      console.log(d)
      that.languageSet = d
    })
    this.languageSet = localStorage.getItem('languageSet')
    // console.log(localStorage.getItem('languageSet'))
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 170px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
.el-tabs__item {
  font-size: 20px;
}
.el-tabs__item:hover {
  color: #3246f4;
}
.el-tabs__item.is-active {
  color: #3246f4;
}
</style>
